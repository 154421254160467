import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));
const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },

];
function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rows = [
    // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData('Eclair', 262, 16.0, 24, 6.0),
    // createData('Cupcake', 305, 3.7, 67, 4.3),
    // createData('Gingerbread', 356, 16.0, 49, 3.9),
];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


function OrderPage() {
    const [data, setData] = useState([]);
    const [dataDoanhThu, setDataDoanhThu] = useState([]);
    const [dataOrder, setDataOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [valueNgay, setValueNgay] = useState(dayjs());
    const [valueStaff, setValueStaff] = useState(null);
    const [valueMoney, setValueMoney] = useState(0.0);
    const [valueMoneySub, setValueMoneySub] = useState(0.0);
    const [message, setMessage] = useState('');
    const [dataHeader, setDataHeader] = useState([]);
    const [dataHeader1, setDataHeader1] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataBodyTotal, setDataBodyTotal] = useState([]);
    const [dataBody1, setDataBody1] = useState([]);
    const [valueNote, setValueNote] = useState("");
    const [valueNoteEdit, setValueNoteEdit] = useState("");
    useEffect(() => {
        handlePost();

        const fngay = valueNgay.format("YYYY-MM-DD");
        getOrder(fngay);
        getDoanhThu(fngay);
    }, []);

    const handlePost = () => {
        fetch('https://hoangthongtelecom.com/ERP/API/Staff/get.php')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data); // Update state with fetched data
                setLoading(false); // Set loading to false
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    const getOrder = (ngay) => {

        const fngay = ngay;
        const tngay = ngay;
        fetch('https://hoangthongtelecom.com/ERP/API/Order/test1.php', {
            method: 'POST', // Thay đổi phương thức thành POST
            headers: {
                'Content-Type': 'application/json', // Định dạng kiểu dữ liệu
            },
            body: JSON.stringify({
                // Dữ liệu bạn muốn gửi đi
                fromNgay: fngay,
                toNgay: tngay,
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((dataAPI) => {
                let itemWithMaxOrders1 = null;
                let maxLength1 = 0;
                const data = dataAPI.map((item) => {
                    const filteredOrders = item.value_order.filter(
                        (order) => order.customer_Order === '0' || order.customer_Order === "0"
                    );
                    if (filteredOrders.length > maxLength1) {
                        maxLength1 = filteredOrders.length;
                        itemWithMaxOrders1 = {
                            ...item,
                            value_order: JSON.stringify(filteredOrders), // Convert back to a string if necessary
                        };
                    }
                    return {
                        ...item,
                        value_order: JSON.stringify(filteredOrders), // Convert back to a string if necessary
                    };
                }).filter(item => item.value_order.length > 0);
                setDataOrder(data); // Lưu rows vào state 'dataOrder
               
                const parsedData = data.map(item => {
                    return {
                        ...item,
                        value_order: JSON.parse(item.value_order)
                    };
                });
                setDataBody(parsedData);
                // Tìm đối tượng có count_Row lớn nhất

                const maxCountRowItem = itemWithMaxOrders1;
                const rowFind = JSON.parse(maxCountRowItem.value_order);
                setDataHeader(rowFind);
               
                let itemWithMaxOrders = null;
                let maxLength = 0;
                const data1 = dataAPI.map((item1) => {

                    const filteredOrders1 = item1.value_order.filter(i => i.customer_Order === '1' || i.customer_Order === "1");

                    if (filteredOrders1.length > maxLength) {
                        maxLength = filteredOrders1.length;
                        itemWithMaxOrders = {
                            ...item1,
                            value_order: JSON.stringify(filteredOrders1), // Convert back to a string if necessary
                        };
                    }
                    return {
                        ...item1,
                        value_order: JSON.stringify(filteredOrders1), // Convert back to a string if necessary
                    };


                }).filter((item) => item !== null);
            
                const parsedData1 = data1.map(item => {
                    return {
                        ...item,
                        value_order: JSON.parse(item.value_order)
                    };
                });
                setDataBody1(parsedData1);
                const maxCountRowItem1 = itemWithMaxOrders;
                const rowFind1 = JSON.parse(maxCountRowItem1.value_order);
                setDataHeader1(rowFind1);
                setLoading(false); // Set loading to false khi hoàn thành
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    const processData = (data, customerOrderValue) => {
        const filteredData = data.map((item) => {
            const filteredOrders = JSON.parse(item.value_order).filter(
                (order) => order.customer_Order === customerOrderValue
            );
            return {
                ...item,
                value_order: filteredOrders, // Keep value_order as an array
            };
        }).filter(item => item.value_order.length > 0); // Remove items with no matching orders

        return filteredData;
    };

    // Lấy danh sách các tên và chiều dài lớn nhất của mảng money
    // const names = Object.keys(groupedData);
    // const maxLength = Math.max(...Object.values(groupedData).map(arr => arr.length));
    const [ranks, setRanks] = useState([]); // Lưu các rank từ dữ liệu
    const getDoanhThu = (ngay) => {
        const fngay = ngay;
        const tngay = ngay;
        // fetch('https://hoangthongtelecom.com/ERP/API/Order/getDoanhThuNgay.php',
        fetch('https://hoangthongtelecom.com/ERP/API/Order/get.php',
            {
                method: 'POST', // Thay đổi phương thức thành POST
                headers: {
                    'Content-Type': 'application/json', // Định dạng kiểu dữ liệu

                },
                body: JSON.stringify({
                    // Dữ liệu bạn muốn gửi đi
                    fromNgay: fngay,
                    toNgay: tngay,

                })
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setDataDoanhThu(data); // Update state with fetched data               
                setLoading(false); // Set loading to false
                // Tìm tất cả các rank hiện có từ dữ liệu
                const allRanks = data.flatMap(item => item.data_order.map(order => Number(order.rank)));
                // Lưu các rank duy nhất vào ranks
                const uniqueRanks = [...new Set(allRanks)];
                setRanks(uniqueRanks.sort((a, b) => a - b)); // Sắp xếp các rank theo thứ tự tăng dần
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    const getOrdersByRank = (orders, targetRank) => {
        return orders.filter(order => Number(order.rank) === targetRank);
    };
    const saveOrder = () => {
        const ranks = dataDoanhThu.flatMap(item => item.data_order.map(order => Number(order.rank)));
        // Tìm rank nhỏ nhất
        const minRank = Math.min(...ranks);
        const maxRank = Math.max(...ranks);

        let checkUpdate = false;
        let updatedData1 = dataDoanhThu.map(item => ({
            ...item,
            data_order: item.data_order.flatMap(order => {

                if (Number(order.rank) === minRank) {
                    if (Number(order.money_Order) === 0) {
                        const updatedOrder = {
                            ...order,
                            rank: checked === true ? minRank : maxRank + 1,  // Cập nhật rank nhỏ nhất thành rank lớn nhất
                            money_Order: (parseFloat(order.money_Order) + parseFloat(valueMoney)).toFixed(2),  // Cộng thêm 10 vào money_Order
                            money_Sub: valueMoneySub,
                            note: valueNote
                            //   percent : ((parseFloat(order.money_Order) + parseFloat(valueMoney)).toFixed(2)
                        };
                        return [updatedOrder];
                    }
                    // Nếu money_Order > 0 thì giữ nguyên và add thêm dòng mới
                    else if (Number(order.money_Order) > 0) {

                        checkUpdate = true;
                        return [order]; // Giữ dòng hiện tại và thêm dòng mới
                    }
                }
                // Nếu rank của order là rank nhỏ nhất và money_Order = 0

                // Trả về các đơn hàng khác không thay đổi
                return [order];
            })
        }));
        let dataNew = [];
        let idUpdate = '';
        if (checkUpdate === false) {
            dataNew = updatedData1;
            setDataDoanhThu(updatedData1);
            const allRanks = updatedData1.flatMap(item => item.data_order.map(order => Number(order.rank)));
            // Lưu các rank duy nhất vào ranks
            const uniqueRanks = [...new Set(allRanks)];
            setRanks(uniqueRanks.sort((a, b) => a - b)); // Sắp xếp các rank theo thứ tự tă
        } else {
            const newItem = {
                staff_Id: valueStaff.ID,
                name: valueStaff.name,
                money_Order: valueMoney,
                rank: checked === true ? minRank : maxRank + 1,  // Rank mới có thể là chiều dài mảng data_order hiện tại
                percent: valueStaff.commission_rate,
                money_Sub: valueMoneySub,
                customer_Order: checked === true ? 1 : 0,
                note: valueNote
            };
            const dataNew = dataDoanhThu.map(item => {
                if (item.staff_Id === valueStaff.ID) {
                    return {
                        ...item,
                        data_order: [...item.data_order, newItem]  // Thêm mục mới vào data_order
                    };
                }
                return item;  // Trả về dữ liệu không thay đổi cho các nhân viên khác
            });

            const newDataOrder = dataNew.map(item => {
                // Kiểm tra nếu staff_Id khớp với targetStaffId
                if (item.staff_Id === valueStaff.ID) {
                    // Tính max rank từ data_order của staff_Id hiện tại
                    const maxRank = Math.max(...item.data_order.map(order => order.rank));

                    return {
                        ...item,
                        data_order: item.data_order.map(order => ({
                            ...order,
                            rank: maxRank  // Cập nhật rank thành maxRank
                        }))
                    };
                }
                return item; // Không thay đổi nếu không khớp
            });

            // Cập nhật state với dữ liệu mới chứa newItem đã thêm.
            setDataDoanhThu(newDataOrder);
            const allRanks = newDataOrder.flatMap(item => item.data_order.map(order => Number(order.rank)));
            // Lưu các rank duy nhất vào ranks
            const uniqueRanks = [...new Set(allRanks)];
            setRanks(uniqueRanks.sort((a, b) => a - b)); // Sắp xếp các rank theo thứ tự tăng dần

        }


        const fngaySave = valueNgay.format("YYYY-MM-DD");
        setValueMoney(0.0);
        setValueStaff(null);
        setChecked(false);
        setValueNote('');
        setValueMoneySub(0.0);
        const requestData = {
            staff_Id: valueStaff.ID,
            date_Order: fngaySave,
            money_Order: valueMoney,
            customer_Order: checked === true ? 1 : 0,
            id_Update: idUpdate,
            money_Sub: valueMoneySub === "" ? 0 : valueMoneySub,
            rank: checked === true ? minRank : maxRank + 1,
            note: valueNote

        };
        fetch('https://hoangthongtelecom.com/ERP/API/Order/Add.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {

                const fngay = valueNgay.format("YYYY-MM-DD");
                getOrder(fngay);
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [contextMenu, setContextMenu] = useState(null);
    const [valueEdit, setValueEdit] = useState([]);
    const [valueEditStaff, setValueEditStaff] = useState('');
    const [valueEditMoney, setValueEditMoney] = useState(0);
    const [valueEditMoneySub, setValueEditMoneySub] = useState(0);
    const [valueIdOrder, setValueIdOrder] = useState('');
    const [valueRow, setValueRow] = useState(0);
    const handleRightClick = (event, rowData, intRow) => {
        event.preventDefault(); // Prevent the default right-click menu
        setValueEdit(rowData);
        setValueRow(intRow);
        setValueEditStaff(rowData.name);
        rowData.value_order.map((item, index) => {
            if (index === intRow) {
                setValueEditMoney(item.money_Order);
                setValueEditMoneySub(item.money_Sub);
                setValueIdOrder(item.id_Order);
                setValueNoteEdit(item.note);
            }
        })
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    rowData: rowData,
                }
                : null,
        );
    };
    const handleClose = () => {
        setContextMenu(null);
    };
    useEffect(() => {
        // Add event listener to detect clicks outside the table to close the menu
        const handleClickOutside = (event) => {
            // Check if the click is outside both the table and the context menu
            if (
                contextMenu &&
                !event.target.closest('.clickable-table') &&
                !event.target.closest('.context-menu')
            ) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [contextMenu]);


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setContextMenu(null);
    };
    const handleCloseDialog = () => {
        setOpen(false);
    };
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const update = () => {
        const requestData = {
            id_Order: valueIdOrder,
            money_Order: valueEditMoney,
            money_Sub: valueEditMoneySub,
            note: valueNoteEdit

        };
        fetch('https://hoangthongtelecom.com/ERP/API/Order/update.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                setOpenSnackbar(true);
                setOpen(false);

                const staff = dataDoanhThu.find(item => item.staff_Id === valueEdit.ID);

                if (staff && staff.data_order.length > 0) {
                    // Update the money_Order
                    staff.data_order[valueRow].money_Order = valueEditMoney;
                }
                const fngay = valueNgay.format("YYYY-MM-DD");
                getOrder(fngay);
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    }
    const formatNote = (value) => {
        return `(${value})`; // Định dạng để hiển thị dưới dạng (note)
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Thành công
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={6} md={2}>
                    <div>
                        <div>Ngày</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                    },
                                }}
                                value={valueNgay}
                                format='DD/MM/YYYY'
                                onChange={(newValue) => {
                                    const formattedDate = newValue.format('DD/MM/YYYY');
                                    setValueNgay(dayjs(formattedDate, 'DD/MM/YYYY'));
                                    getDoanhThu(newValue.format('YYYY-MM-DD'));
                                    getOrder(newValue.format('YYYY-MM-DD'));
                                }}
                            />
                        </LocalizationProvider>
                    </div>

                </Grid>
                <Grid item xs={6} md={2}>
                    <div>
                        <div>Nhân viên</div>
                        <Autocomplete
                            disablePortal
                            options={data}
                            value={valueStaff}
                            getOptionLabel={(options) => options.name}
                            sx={{
                                width: "100%",
                                '& .MuiInputBase-root': {
                                    height: 40, // Tùy chỉnh chiều cao
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '0 4px', // Điều chỉnh padding bên trong input
                                },
                            }}
                            onChange={(event, newValue) => {
                                setValueStaff(newValue); // Cập nhật giá trị mới được chọn vào state
                            }}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                    </div>

                </Grid>
                <Grid item xs={6} md={4}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <div>Số tiền</div>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                        width: 100
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: '0 14px', // Điều chỉnh padding bên trong
                                    },
                                }}
                                value={valueMoney}
                                onChange={(e) => setValueMoney(e.target.value)}
                            />
                        </div>
                        <div>
                            <div></div>
                            <div style={{ height: '40px', marginLeft: '5px', marginRight: '5px', marginTop: '30px' }}>+</div>
                        </div>
                        <div>
                            <div>Tiền thêm</div>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                        width: 100
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: '0 14px', // Điều chỉnh padding bên trong
                                    },
                                }}
                                value={valueMoneySub}
                                onChange={(e) => setValueMoneySub(e.target.value)}
                            />
                        </div>
                        <div>
                            <div></div>
                            <div style={{ height: '40px', marginLeft: '5px', marginRight: '5px', marginTop: '30px' }}></div>
                        </div>
                        <div>
                            <div>Ghi chú</div>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: '0 14px', // Điều chỉnh padding bên trong
                                    },
                                }}
                                value={valueNote}
                                onChange={(e) => setValueNote(e.target.value)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} md={2}>
                    <div>
                        <div>Khách đặt</div>
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>

                </Grid>
                <Grid item xs={12} md={1}>
                    <div style={{ marginTop: '25px' }}>
                        <Button variant="contained" onClick={() => saveOrder()} style={{ width: '100%' }}>Thêm</Button>
                    </div>

                </Grid>
            </Grid>

            <div style={{ marginTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div style={{ width: '100%', marginTop: '0px' }}>
                            <TableContainer component={Paper}>
                                <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: 'teal', color: 'white' }}>
                                            <TableCell sx={{ color: 'white' }}>Nhân viên</TableCell>
                                            <TableCell align="right" sx={{ color: 'white' }}>Doanh thu</TableCell>
                                            <TableCell align="right" sx={{ color: 'white' }}>Hoa hồng</TableCell>
                                            <TableCell align="right" sx={{ color: 'white' }}>Khách đặt</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ranks.map((rank) => (
                                            // Không cần <div> ở đây
                                            dataDoanhThu.map((item) => {
                                                const ordersForRank = getOrdersByRank(item.data_order, rank);
                                                return ordersForRank.map((order, orderIndex) => (
                                                    <TableRow
                                                        key={orderIndex}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {order.name}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <div style={{ display: 'flex', textAlign: 'end', justifyContent: 'flex-end' }}>
                                                                <div>{parseInt(order.money_Order)}</div>
                                                                <div>{order.money_Sub === "0.00" || order.money_Sub === 0 ? "" : "+"}</div>
                                                                <div style={{ color: 'red' }}>{order.money_Sub === "0.00" || order.money_Sub === 0 ? "" : parseInt(order.money_Sub)}</div>
                                                                <div style={{ color: 'blue', marginLeft: '3px' }}>{order.note === '' ? "" : formatNote(order.note)}</div>
                                                            </div></TableCell>
                                                        <TableCell align="right">{(parseFloat(order.money_Order) + parseInt(order.money_Sub)) * (parseFloat(order.percent) / 100).toFixed(1)}</TableCell>
                                                        <TableCell align="right">{order.customer_Order}</TableCell>
                                                    </TableRow>
                                                ));
                                            })
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TableContainer component={Paper}>
                            <Table size='small' sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'teal', color: 'white' }}>
                                        <TableCell sx={{ color: 'white' ,width:'30px'}}>STT</TableCell>
                                        <TableCell sx={{ color: 'white', width:'80px' }}></TableCell>
                                        {dataOrder.map((item, index) => (
                                            <TableCell sx={{ color: 'white',padding: '6px 10px' }}>{item.name}</TableCell>
                                        ))}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataHeader.map((item, index) => (

                                        <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                                            <TableCell sx={{ color: 'black' , border: '1px solid #ccc', width:'30px',padding: '6px 10px'}}>{index + 1}</TableCell>
                                            <TableCell sx={{ color: 'black', width: '80px', border: '1px solid #ccc' }}>
                                                <div>
                                                    <div>Doanh thu</div>
                                                    <div>Hoa hồng</div>
                                                </div>

                                            </TableCell>
                                            {

                                                dataBody.map((_, col) => (

                                                    <TableCell sx={{ border: '1px solid #ccc',width:'auto',padding: '6px 14px' }} onContextMenu={(event) => handleRightClick(event, _, index)}>

                                                        {_.value_order.map((order, orderIndex) => {
                                                            if (orderIndex === index) {
                                                                if (order.customer_Order === "0") {
                                                                    return (
                                                                        <div key={orderIndex}>
                                                                            <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                                <div>{parseInt(order.money_Order)}</div>
                                                                                <div>{order.money_Sub === "0.00" || order.money_Sub === "0" ? "" : "+"
                                                                                }</div>
                                                                                <div style={{ color: 'red' }}>{order.money_Sub === "0.00" || order.money_Sub === "0" ? "" : (parseInt(order.money_Sub)).toString()}</div>
                                                                                <div style={{ color: 'blue', marginLeft: '3px' }}>{order.note === '' ? "" : formatNote(order.note)}</div>
                                                                            </div>

                                                                            <div style={{ display: 'flex', justifyContent: 'left' }}>{(parseInt(order.money_Order) + parseInt(order.money_Sub)) * (_.commission_rate / 100)}</div>
                                                                        </div>
                                                                    );
                                                                }

                                                            } else {
                                                                return null; // Không render gì nếu không thỏa mãn điều kiện
                                                            }
                                                        })}

                                                    </TableCell>
                                                ))
                                            }


                                        </TableRow>

                                    ))}

                                    {dataHeader1.map((item, index) => (

                                        <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                                            <TableCell sx={{ color: 'blue',border: '1px solid #ccc' }}>KĐ</TableCell>
                                            <TableCell sx={{ color: 'blue', width: '100px',border: '1px solid #ccc' }}>
                                                <div>
                                                    <div>Doanh thu</div>
                                                    <div>Hoa hồng</div>
                                                </div>

                                            </TableCell>
                                            {

                                                dataBody1.map((_, col) => (

                                                    <TableCell sx={{ color: 'blue',border: '1px solid #ccc' }} onContextMenu={(event) => handleRightClick(event, _, index)}>

                                                        {_.value_order.map((order, orderIndex) => {
                                                            if (orderIndex === index) {
                                                                return (
                                                                    <div key={orderIndex}>
                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                                                            <div>{parseInt(order.money_Order)}</div>
                                                                            <div>{order.money_Sub === "0.00" || order.money_Sub === "0" ? "" : "+"
                                                                            }</div>
                                                                            <div style={{ color: 'red' }}>{order.money_Sub === "0.00" || order.money_Sub === "0" ? "" : (parseInt(order.money_Sub)).toString()}</div>
                                                                            <div style={{ color: 'blue', marginLeft: '3px' }}>{order.note === '' ? "" : formatNote(order.note)}</div>
                                                                        </div>

                                                                        <div style={{ display: 'flex', justifyContent: 'left' }}>{(parseInt(order.money_Order) + parseInt(order.money_Sub)) * (_.commission_rate / 100)}</div>
                                                                    </div>
                                                                );

                                                            } else {
                                                                return null; // Không render gì nếu không thỏa mãn điều kiện
                                                            }

                                                        })}

                                                    </TableCell>
                                                ))
                                            }


                                        </TableRow>

                                    ))}

                                    <TableRow>

                                        <TableCell style={{ backgroundColor: '#D9E1F2', fontWeight: 500 }}>Tổng</TableCell>
                                        <TableCell sx={{ color: 'black', width: '100px', backgroundColor: '#D9E1F2', fontWeight: 500 }}>
                                            <div>
                                                <div>Doanh thu</div>
                                                <div>Hoa hồng</div>
                                            </div>

                                        </TableCell>
                                        {dataBody.map((item) => (
                                            <TableCell style={{ backgroundColor: '#D9E1F2', fontWeight: 600 }}>
                                                <div>
                                                    <div>{item.total_money}</div>
                                                    <div>{item.total_money * (item.commission_rate / 100)}</div>
                                                </div>

                                            </TableCell>

                                        ))}
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <table border="1">
                            <thead>
                                <tr>
                                    {dataOrder.map((item, index) => (
                                        <th key={index}>{item.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataHeader.map((item, index) => (
                                    <tr key={index}>
                                        {
                                            dataBody.map((_, col) => (

                                                <td>

                                                    {_.value_order.map((order, orderIndex) => {
                                                        if (orderIndex === index) {
                                                            return (
                                                                <div key={orderIndex}>
                                                                    {order.money_Order}
                                                                </div>
                                                            );
                                                        } else {
                                                            return null; // Không render gì nếu không thỏa mãn điều kiện
                                                        }
                                                    })}

                                                </td>
                                            ))
                                        }


                                    </tr>
                                ))}

                            </tbody>
                        </table> */}

                    </Grid>

                </Grid>

            </div>
            <BootstrapDialog
                onClose={handleCloseDialog}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Edit
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <div>
                        <div style={{ marginBottom: '10px', width: '250px' }}>
                            <div>Nhân viên</div>
                            <TextField id="outlined-basic" value={valueEditStaff} fullWidth size='small' variant="outlined" />
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            <div>Số tiền</div>
                            <TextField id="outlined-basic" value={valueEditMoney} onChange={(e) => setValueEditMoney(e.target.value)} fullWidth size='small' variant="outlined" />
                        </div>
                        <div>
                            <div>Tiền thêm</div>
                            <TextField id="outlined-basic" value={valueEditMoneySub} onChange={(e) => setValueEditMoneySub(e.target.value)} fullWidth size='small' variant="outlined" />
                        </div>
                        <div>
                            <div>Ghi chú</div>
                            <TextField id="outlined-basic" value={valueNoteEdit} onChange={(e) => setValueNoteEdit(e.target.value)} fullWidth size='small' variant="outlined" />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => update()}>
                        Lưu
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            {contextMenu && (
                <div
                    className="context-menu" // Add this class to the context menu container
                    style={{
                        position: 'absolute',
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX,
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                        padding: '10px',
                        zIndex: 1000,
                    }}
                    onClick={(event) => event.stopPropagation()} // Prevent click from propagating
                >
                    <div className='btn-edit' onClick={handleClickOpen}>Edit</div>
                </div>
            )}
        </Box>
    );
}
export default OrderPage