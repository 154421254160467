import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import Button from '@mui/material/Button';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import OrderPage from './order';
import StaffPage from './staff';
import ReportPage from './report';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WorkPage from './work';

const drawerWidth = 155;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function HomePage() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    const token = localStorage.getItem('token_erp'); // Lấy token từ localStorage
    if (token) {
      setIsLoggedIn(true); // Nếu có token, đặt trạng thái đăng nhập là true
    }
  }, []);
  const [valueTabLeft, setValueTabLeft] = React.useState(0);
  const [valueUser, setValueUser] = React.useState('');
  const [valuePass, setValuePass] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [thongbao, setThongbao] = React.useState('');
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const login = () => {

    const requestData = {
      user: valueUser,
      pass: valuePass,

    };
    fetch('https://hoangthongtelecom.com/ERP/API/Login/get.php', {
      method: 'POST', // Phương thức yêu cầu
      headers: {
        'Content-Type': 'application/json', // Định dạng của body là JSON
      },
      body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // Chuyển đổi kết quả trả về thành JSON

      })
      .then((data) => {
        if (data.trim() === 'OK') {
          const token = 'Ix8TxYBLRWpShcA7wdwU0GmO635H0yDYspZl3HAQ';
          localStorage.setItem('token_erp', token); // Lưu token vào localStorage
          setIsLoggedIn(true); // Cập nhật trạng thái đăng nhập
          setMessage('Form submitted successfully!');
        } else {
          setThongbao("Tên đăng nhập hoặc mật khẩu không đúng")
        }

      })
      .catch((error) => {
        setMessage('Error submitting form: ' + error.message);
      });
  }
  if (!isLoggedIn) {
    return (
      <div style={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <h2>LOGIN</h2>
            <div style={{ textAlign: 'start' }}>
              <div style={{ marginBottom: '5px' }}>Tên đăng nhập</div>
              <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    height: 40, // Tùy chỉnh chiều cao
                  },
                  '& .MuiOutlinedInput-root': {
                    padding: '0 14px', // Điều chỉnh padding bên trong
                  },
                }}
                value={valueUser}
                onChange={(e) => setValueUser(e.target.value)}
              />
            </div>
            <div style={{ textAlign: 'start', marginTop: '15px' }}>
              <div style={{ marginBottom: '5px' }}>Mật khẩu</div>
              {/* <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    height: 40, // Tùy chỉnh chiều cao
                  },
                  '& .MuiOutlinedInput-root': {
                    padding: '0 14px', // Điều chỉnh padding bên trong
                  },
                }}
                value={valuePass}
                onChange={(e) => setValuePass(e.target.value)}
              /> */}
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                style={{ height: '40px' }}
                value={valuePass}
                onChange={(e) => setValuePass(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>

            <div style={{ marginTop: '10px', color: 'red', fontSize: 14 }}>{thongbao}</div>
            <Button style={{ marginTop: '15px' }} onClick={() => login()} variant="contained" fullWidth>Đăng nhập</Button>

          </CardContent>

        </Card>

      </div>
    );
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                mr: 2,
              },
              open && { display: 'none' },
            ]}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 600, fontSize: 22 }}>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}
          ERP
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key={'order'} disablePadding>
            <ListItemButton onClick={() => setValueTabLeft(0)} style={{ backgroundColor: valueTabLeft === 0 ? 'teal' : 'white' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <ShoppingBagOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Bán hàng'} style={{ color: valueTabLeft === 0 ? 'white' : 'black' }} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'work'} disablePadding>
            <ListItemButton onClick={() => setValueTabLeft(3)} style={{ backgroundColor: valueTabLeft === 3 ? 'teal' : 'white' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <CalendarMonthIcon />
              </ListItemIcon>
              <ListItemText primary={'Lịch làm việc'} style={{ color: valueTabLeft === 3 ? 'white' : 'black' }} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'report'} disablePadding>
            <ListItemButton onClick={() => setValueTabLeft(1)} style={{ backgroundColor: valueTabLeft === 1 ? 'teal' : 'white' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <AssessmentOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Báo cáo'} style={{ color: valueTabLeft === 1 ? 'white' : 'black' }} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'staff'} disablePadding>
            <ListItemButton onClick={() => setValueTabLeft(2)} style={{ backgroundColor: valueTabLeft === 2 ? 'teal' : 'white' }}>
              <ListItemIcon sx={{ minWidth: '30px' }}>
                <PeopleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={'Nhân viên'} style={{ color: valueTabLeft === 2 ? 'white' : 'black' }} />
            </ListItemButton>
          </ListItem>
        </List>

      </Drawer>
      <Main open={open} style={{padding:'10px'}}>
        {/* <DrawerHeader /> */}
        {
          valueTabLeft === 0 && <OrderPage />
        }
        {
          valueTabLeft === 1 && <ReportPage />
        }
        {
          valueTabLeft === 2 && <StaffPage />
        }
        {
          valueTabLeft === 3 && <WorkPage/>
        }

      </Main>
    </Box>
  );
}