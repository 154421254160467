import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import Alert from '@mui/material/Alert';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));
const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },

];
function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rows = [
    // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData('Eclair', 262, 16.0, 24, 6.0),
    // createData('Cupcake', 305, 3.7, 67, 4.3),
    // createData('Gingerbread', 356, 16.0, 49, 3.9),
];
function StaffPage() {
    const [valueName, setValueName] = React.useState('');
    const [valueRate, setValueRate] = React.useState('50');
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const [open, setOpen] = React.useState(false);
    const saveNhanVien = () => {
        const maxId = data.length > 0 ? Math.max(...data.map(item => item.ID)) : 0;
        const newItem = { ID: maxId + 1, name: valueName, commission_rate:valueRate ===''?50:valueRate };

        // Update the state using the spread operator
        setData([...data, newItem]);
        setOpen(true);
        setValueName("");
        const requestData = {
            name: valueName,
            rate: valueRate,

        };
        fetch('https://hoangthongtelecom.com/ERP/API/Staff/Add.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    const [valueEdit, setValueEdit] = React.useState('');
    const [valueNameEdit, setValueNameEdit] = React.useState('');
    const [valuePercentEdit, setValuePercentEdit] = React.useState('');
    const updateNhanVien = () => {
        // const maxId = data.length > 0 ? Math.max(...data.map(item => item.id)) : 0;
        // const newItem = { ID: maxId + 1, name: valueName, commission_rate: valueRate };
        setData(data.map(item =>
            item.ID === valueEdit.ID ? { ...item, commission_rate: valuePercentEdit } : item
        ));
        // Update the state using the spread operator

        setOpen(true);
        setOpenDialog(false);
        const requestData = {
            id: valueEdit.ID,
            percent: valuePercentEdit
        };
        fetch('https://hoangthongtelecom.com/ERP/API/Staff/update.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    const deleteNhanVien = (id_Staff) => {

        setData(data.filter(item => item.ID !== id_Staff));
        // Update the state using the spread operator

        setOpen(true);
        setOpenDialog(false);
        const requestData = {
            id: id_Staff,
        };
        fetch('https://hoangthongtelecom.com/ERP/API/Staff/delete.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    useEffect(() => {
        handlePost();
    }, []);
    const handlePost = () => {
        fetch('https://hoangthongtelecom.com/ERP/API/Staff/get.php')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data); // Update state with fetched data
                setLoading(false); // Set loading to false
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };

    const handleClose = () => {

        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>

        </React.Fragment>
    );
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={4000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Thành công
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>

                <Grid item xs={6} md={3}>
                    <div>
                        <div>Nhân viên</div>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: 40, // Tùy chỉnh chiều cao
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '0 14px', // Điều chỉnh padding bên trong
                                },
                            }}
                            value={valueName}
                            onChange={(e) => setValueName(e.target.value)}
                        />
                    </div>
                </Grid>
                <Grid item xs={6} md={2}>
                    <div>
                        <div>Hoa hồng</div>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: 40, // Tùy chỉnh chiều cao
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '0 14px', // Điều chỉnh padding bên trong
                                },
                            }}
                            value={valueRate}
                            onChange={(e) => setValueRate(e.target.value)}
                        />

                    </div>

                </Grid>
                <Grid item xs={12} md={1}>
                    <div style={{ marginTop: '25px' }}>
                        <Button variant="contained" style={{ width: '100%' }} onClick={(e) => saveNhanVien()}>Thêm</Button>
                    </div>

                </Grid>
            </Grid>
            <div style={{ marginTop: '10px' }}>
                <TableContainer component={Paper} sx={{ maxWidth: 750, minWidth: 700 }}>
                    <Table size='small' sx={{ maxWidth: 750, minWidth: 700 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'teal', color: 'white' }}>
                                <TableCell sx={{ color: 'white' }}>Nhân viên</TableCell>
                                <TableCell sx={{ color: 'white' }} align="right">Hoa hồng (%)</TableCell>
                                <TableCell sx={{ color: 'white' }} align="center">Hành động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.commission_rate}</TableCell>
                                    <TableCell align="center">
                                        <div style={{ display: 'flex', width: '100%', textAlign: 'right', justifyContent: 'center' }}>
                                            <div><IconButton aria-label="edit" size="small">
                                                <EditIcon fontSize="inherit" onClick={() => {
                                                    setValueNameEdit(row.name);
                                                    setValuePercentEdit(row.commission_rate);
                                                    setValueEdit(row);
                                                    handleClickOpen();
                                                }} />
                                            </IconButton></div>
                                            <div><IconButton aria-label="delete" size="small" onClick={() => deleteNhanVien(row.ID)}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton></div>
                                            <div></div>
                                        </div>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Chỉnh sửa thông tin"}
                </DialogTitle>
                <DialogContent>

                    <div>
                        <div>Nhân viên</div>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: 40, // Tùy chỉnh chiều cao
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '0 14px', // Điều chỉnh padding bên trong
                                },
                            }}
                            value={valueNameEdit}
                            onChange={(e) => setValueNameEdit(e.target.value)}
                        />
                    </div>
                    <div>
                        <div>Hoa hồng</div>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            sx={{
                                '& .MuiInputBase-root': {
                                    height: 40, // Tùy chỉnh chiều cao
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '0 14px', // Điều chỉnh padding bên trong
                                },
                            }}
                            value={valuePercentEdit}
                            onChange={(e) => setValuePercentEdit(e.target.value)}
                        />

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => updateNhanVien()} autoFocus>
                        Lưu
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default StaffPage