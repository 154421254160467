import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import { format, eachDayOfInterval, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';

function WorkPage() {
    const [mode, setMode] = useState('week'); // Chế độ mặc định là 'week'
    const [selectedDate, setSelectedDate] = useState(new Date()); // Ngày hiện tại
    const [valueNgay, setValueNgay] = useState(dayjs());
    const [valueNgayEdit, setValueNgayEdit] = useState(dayjs());
    const [valueStaff, setValueStaff] = useState('');
    const [valueStaffEdit, setValueStaffEdit] = useState('');
    const [valueStaffSelect, setValueStaffSelect] = useState('');
    const [dataStaff, setDataStaff] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const [valueSTT, setValueSTT] = useState('1');
    const [valueSTTEdit, setValueSTTEdit] = useState('');
    const [data, setData] = useState([
        // { id: 1, Ngay: '2024-09-29', name: 'TRING', fromTime: '08:00', toTime: '09:00' },
        // { id: 2, Ngay: '2024-09-29', name: 'QUY', fromTime: '09:00', toTime: '10:00' },
        // { id: 2, Ngay: '2024-10-02', name: 'QUY', fromTime: '08:00', toTime: '09:00' },
        // { id: 1, Ngay: '2024-10-02', name: 'TRING', fromTime: '09:00', toTime: '10:00' },
        // { id: 3, Ngay: '2024-10-02', name: 'NANG', fromTime: '10:00', toTime: '11:00' },
    ]);
    useEffect(() => {

        handlePostWork();
        handlePost();
    }, []);

    const handlePost = () => {
        fetch('https://hoangthongtelecom.com/ERP/API/Staff/get.php')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setDataStaff(data); // Update state with fetched data
                setLoading(false); // Set loading to false
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    const handlePostWork = () => {
        fetch('https://hoangthongtelecom.com/ERP/API/Work/get1.php')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data); // Update state with fetched data
                setLoading(false); // Set loading to false
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    // Tạo mảng chứa các ngày của tuần hiện tại hoặc tháng hiện tại
    const getDaysArray = () => {
        if (mode === 'week') {
            return eachDayOfInterval({
                start: startOfWeek(selectedDate),
                end: endOfWeek(selectedDate),
            });
        } else {
            return eachDayOfInterval({
                start: startOfMonth(selectedDate),
                end: endOfMonth(selectedDate),
            });
        }
    };

    const daysArray = getDaysArray(); // Lấy mảng ngày dựa theo tuần hoặc tháng

    // Dữ liệu mẫu cho từng nhân viên theo ngày


    // Nhóm dữ liệu theo nhân viên
    const groupedData = data.reduce((acc, item) => {
        if (!acc[item.name]) {
            acc[item.name] = [];
        }
        acc[item.name].push(item);
        return acc;
    }, {});

    // Hàm sắp xếp theo thời gian
    const sortDataByTime = (dataForDay) => {
        return dataForDay.sort((a, b) => {
            return a.fromTime.localeCompare(b.fromTime); // So sánh thời gian
        });
    };
    const addWork = () => {
        const formattedDate = valueNgay.format('YYYY-MM-DD');
        // const from_Time = "0000-00-00";
        // const to_Time = "0000-00-00";
        // const newItem = { id: valueStaff.ID, name: valueStaff.name, Ngay: formattedDate, fromTime: from_Time, toTime: to_Time };
        // setData([...data, newItem]);

        const requestData = {
            ngay: formattedDate,
            id: valueStaff.ID,
            rank: valueSTT

        };
        fetch('https://hoangthongtelecom.com/ERP/API/Work/add1.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                handlePostWork();
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    const [selectedTime, setSelectedTime] = useState(dayjs()); // Khởi tạo giá trị thời gian mặc định
    const [selectedToTime, setSelectedToTime] = useState(dayjs()); // Khởi tạo giá trị thời gian mặc định

    const [selectedTimeEdit, setSelectedTimeEdit] = useState(dayjs()); // Khởi tạo giá trị thời gian mặc định
    const [selectedToTimeEdit, setSelectedToTimeEdit] = useState(dayjs());
    const handleTimeChange = (newValue) => {
        setSelectedTime(newValue); // Cập nhật state với giá trị thời gian mới
    };
    const handleToTimeChange = (newValue) => {
        setSelectedToTime(newValue); // Cập nhật state với giá trị thời gian mới
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (data) => {
        const ngay_Work = dayjs(data.Ngay, 'DD/MM/YYYY');
        setValueNgayEdit(dayjs(data.Ngay))
        setSelectedTimeEdit(dayjs(data.fromTime, 'HH:mm:ss'));
        setSelectedToTimeEdit(dayjs(data.toTime, 'HH:mm:ss'));
        setValueStaffEdit(data.name);
        setValueStaffSelect(data.ID);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTimeChangeEdit = (newValue) => {
        setSelectedTimeEdit(newValue); // Cập nhật state với giá trị thời gian mới
    };
    const handleTimeChangeToEdit = (newValue) => {
        setSelectedToTimeEdit(newValue);
    }
    const handleUpdate = () => {
        const formattedDate = valueNgayEdit.format('YYYY-MM-DD');

        setOpen(false);

        const requestData = {
            ngay: formattedDate,
            id: valueStaffSelect,
            rank: valueSTTEdit

        };
        fetch('https://hoangthongtelecom.com/ERP/API/Work/update.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                handlePostWork();
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    const handleDelete = () => {


        const updatedItems = data.filter((item) => item.ID !== valueStaffSelect);
        setData(updatedItems);
        setOpen(false);

        const requestData = {

            id: valueStaffSelect,

        };
        fetch('https://hoangthongtelecom.com/ERP/API/Work/delete.php', {
            method: 'POST', // Phương thức yêu cầu
            headers: {
                'Content-Type': 'application/json', // Định dạng của body là JSON
            },
            body: JSON.stringify(requestData), // Chuyển đổi object thành JSON
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Chuyển đổi kết quả trả về thành JSON
            })
            .then((data) => {
                setMessage('Form submitted successfully!');
            })
            .catch((error) => {
                setMessage('Error submitting form: ' + error.message);
            });
    }
    return (
        <div>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={2}>
                        <div>
                            <div>Ngày</div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            height: 40, // Tùy chỉnh chiều cao
                                        },
                                    }}
                                    value={valueNgay}
                                    format='DD/MM/YYYY'
                                    onChange={(newValue) => {
                                        const formattedDate = newValue.format('YYYY-MM-DD');
                                        setValueNgay(dayjs(formattedDate));
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <div>
                            <div>Nhân viên</div>
                            <Autocomplete
                                disablePortal
                                options={dataStaff}
                                getOptionLabel={(options) => options.name}
                                sx={{
                                    width: "100%",
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: '0 4px', // Điều chỉnh padding bên trong input
                                    },
                                }}
                                onChange={(event, newValue) => {
                                    setValueStaff(newValue); // Cập nhật giá trị mới được chọn vào state
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <div>
                            <div>Thứ tự</div>
                            <TextField id="outlined-basic" value={valueSTT} onChange={(e) => setValueSTT(e.target.value)} variant="outlined" />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <div style={{ marginTop: '25px' }}>
                            <Button variant="contained" onClick={addWork} style={{ width: '100%' }}>Thêm</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginTop: '15px' }}>
                <Button variant="contained" onClick={() => setMode('week')}>Tuần</Button>
                <Button variant="contained" onClick={() => setMode('month')}>Tháng</Button>
            </div>

            <Table>
                <TableHead>
                    <TableRow>
                        {daysArray.map((day) => (
                            <TableCell key={day}>{format(day, 'dd/MM')}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* {Object.keys(groupedData).map((name) => (
                        
                    ))} */}
                    {
                        data.length > 0 && <TableRow>
                            {daysArray.map((day) => {
                                const formattedDate = format(day, 'yyyy-MM-dd');
                                // const dataForDay = data.filter(item => item.Ngay === formattedDate);
                                // const sortedDataForDay = sortDataByTime(dataForDay); // Sắp xếp theo thời gian

                                return (
                                    <TableCell key={1}>
                                        <div>
                                            {
                                                data.map((item, index) => (
                                                    item.Ngay === formattedDate ?
                                                        <div onClick={(e) =>{
                                                            setValueStaffSelect(item.id_Work);
                                                            setValueStaffEdit(item.name);
                                                            setValueNgayEdit(dayjs(item.Ngay));
                                                            setOpen(true);
                                                        } } style={{ padding: '5px', backgroundColor: 'teal', margin: '3px', color: 'white', borderRadius: '8px', display: 'flex', cursor: 'pointer' }} key={index}>

                                                            <div style={{ flexGrow: 1 }}>{item.name}</div>
                                                            <Chip sx={{ backgroundColor: 'white' }} size='small' variant="outlined" label={item.rank} />
                                                        </div> : null
                                                ))
                                            }
                                        </div>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    }

                </TableBody>
            </Table>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Thông tin"}
                </DialogTitle>
                <DialogContent>
                    <div style={{ marginBottom: '10px' }}>
                        <div>Ngày</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                    },
                                }}
                                value={valueNgayEdit}
                                format='DD/MM/YYYY'
                                onChange={(newValue) => {
                                    const formattedDate = newValue.format('YYYY-MM-DD');
                                    setValueNgayEdit(dayjs(formattedDate));
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <div>Nhân viên</div>
                        <TextField value={valueStaffEdit} onChange={(e) => setValueStaffEdit(e.target.value)} id="outlined-basic" fullWidth size='small' variant="outlined" />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <div>Thứ tự</div>
                        <TextField id="outlined-basic" value={valueSTTEdit} onChange={(e) => setValueSTTEdit(e.target.value)} variant="outlined" />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} autoFocus>
                        Xóa
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Cancle
                    </Button>
                    <Button onClick={handleUpdate} autoFocus>
                        Lưu
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default WorkPage;