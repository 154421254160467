import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Snackbar from '@mui/material/Snackbar';
import dayjs from 'dayjs';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));
const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },

];
function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rows = [
    // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData('Eclair', 262, 16.0, 24, 6.0),
    // createData('Cupcake', 305, 3.7, 67, 4.3),
    // createData('Gingerbread', 356, 16.0, 49, 3.9),
];
function ReportPage() {
    const [valueName, setValueName] = React.useState('');
    const [valueRate, setValueRate] = React.useState('');
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [valueNgayFrom, setValueNgayFrom] = useState(dayjs());
    const [valueNgayTo, setValueNgayTo] = useState(dayjs());
    const [dataDoanhThu, setDataDoanhThu] = useState([]);
    const [open, setOpen] = React.useState(false);


    useEffect(() => {

        setValueNgayFrom(dayjs(getFirstDayOfMonth()));
    }, []);
    const handlePost = () => {
        const fngay = valueNgayFrom.format("YYYY-MM-DD");
        const tngay = valueNgayTo.format("YYYY-MM-DD");
        fetch('https://hoangthongtelecom.com/ERP/API/Order/getTotal.php', {
            method: 'POST', // Thay đổi phương thức thành POST
            headers: {
                'Content-Type': 'application/json', // Định dạng kiểu dữ liệu

            },
            body: JSON.stringify({
                // Dữ liệu bạn muốn gửi đi
                fromNgay: fngay,
                toNgay: tngay,

            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setData(data); // Update state with fetched data
                setLoading(false); // Set loading to false
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    const handlePostStaff = () => {
        const fngay = valueNgayFrom.format("YYYY-MM-DD");
        const tngay = valueNgayTo.format("YYYY-MM-DD");
        fetch('https://hoangthongtelecom.com/ERP/API/Order/getTotalStaff.php', {
            method: 'POST', // Thay đổi phương thức thành POST
            headers: {
                'Content-Type': 'application/json', // Định dạng kiểu dữ liệu

            },
            body: JSON.stringify({
                // Dữ liệu bạn muốn gửi đi
                fromNgay: fngay,
                toNgay: tngay,

            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setDataDoanhThu(data); // Update state with fetched data
                setLoading(false); // Set loading to false
            })
            .catch((error) => {
                setError(error.message); // Set error message
                setLoading(false); // Set loading to false
            });
    };
    const handleClose = () => {

        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>

        </React.Fragment>
    );
    const getFirstDayOfMonth = () => {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        return firstDay;
    };
    const totalRevenue = data.reduce((accumulator, item) => {
        return accumulator + parseFloat(item.total_revenue);
      }, 0);  // Calculate the total revenue
      const totalHH = data.reduce((accumulator, item) => {
        return accumulator + parseFloat(item.total_money_after_commission);
      }, 0);  // Calculate the total revenue
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
                action={action}
            />
            <Grid container spacing={2}>

                <Grid item xs={6} md={3}>
                    <div>
                        <div>Từ ngày</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                    },
                                }}
                                value={valueNgayFrom}
                                format='DD/MM/YYYY'
                                onChange={(newValue) => {
                                    const formattedDate = newValue.format('DD/MM/YYYY');
                                    setValueNgayFrom(dayjs(formattedDate, 'DD/MM/YYYY'));
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                </Grid>
                <Grid item xs={6} md={2}>
                    <div>
                        <div>Đến ngày</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 40, // Tùy chỉnh chiều cao
                                    },
                                }}
                                value={valueNgayTo}
                                format='DD/MM/YYYY'
                                onChange={(newValue) => {
                                    const formattedDate = newValue.format('DD/MM/YYYY');
                                    setValueNgayTo(dayjs(formattedDate, 'DD/MM/YYYY'));
                                }}
                            />
                        </LocalizationProvider>

                    </div>

                </Grid>
                <Grid item xs={12} md={1}>
                    <div style={{ marginTop: '25px' }}>
                        <Button variant="contained" style={{ width: '100%' }} onClick={(e) => {
                            handlePost();
                            handlePostStaff();

                        }}>Xem</Button>
                    </div>

                </Grid>
            </Grid>
            <div style={{ marginTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <TableContainer component={Paper} sx={{ width:'100%'}}>
                            <Table size='small' sx={{width:'100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'teal', color: 'white' }}>
                                        <TableCell sx={{ color: 'white' }}>Ngày</TableCell>
                                        <TableCell sx={{ color: 'white' }} align="right">Doanh thu</TableCell>
                                        <TableCell sx={{ color: 'white' }} align="right">Hoa hồng</TableCell>
                                        <TableCell sx={{ color: 'white' }} align="right">Lợi nhuận</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow
                                            key={row.order_date}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.date_Order}
                                            </TableCell>
                                            <TableCell align="right">{row.total_revenue}</TableCell>
                                            <TableCell align="right">{row.total_money_after_commission}</TableCell>
                                            <TableCell align="right">{row.total_revenue - row.total_money_after_commission}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow
                                            key={'total'}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" style={{backgroundColor:'#D9E1F2', fontWeight:600}}>
                                               Tổng doanh thu
                                            </TableCell>
                                            <TableCell align="right" style={{backgroundColor:'#D9E1F2', fontWeight:600}}>{totalRevenue}</TableCell>
                                            <TableCell align="right" style={{backgroundColor:'#D9E1F2', fontWeight:600}}>{totalHH}</TableCell>
                                            <TableCell align="right" style={{backgroundColor:'#D9E1F2', fontWeight:600}}>{totalRevenue - totalHH}</TableCell>
                                        </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TableContainer component={Paper}>
                            <Table size='small' sx={{ minWidth: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'teal', color: 'white' }}>
                                        <TableCell sx={{ color: 'white' }}>Nhân viên</TableCell>
                                        <TableCell align="right" sx={{ color: 'white' }}>Doanh thu</TableCell>
                                        <TableCell align="right" sx={{ color: 'white' }}>Hoa hồng</TableCell>
                                        <TableCell align="right" sx={{ color: 'white' }}>Lợi nhuận</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataDoanhThu.map((row) => (
                                        <TableRow
                                            key={row.employee_name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{parseFloat(row.total_money_Order).toFixed(1)}</TableCell>
                                            <TableCell align="right">{row.total_money_Order * (row.commission_rate/100)}</TableCell>
                                            <TableCell align="right">{parseFloat(row.total_money_Order) - (row.total_money_Order * (row.commission_rate/100))}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

            </div>

        </Box>
    );
}

export default ReportPage